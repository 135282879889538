import * as React from "react";
import { useState, useMemo } from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';



export default function FeedMarginTable({ marginInfo }) {
    const [open, setOpen] = React.useState(false);
    const [modalWithData, setModalWithData] = React.useState([]);
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');


    const validateInput = (value) => {
        const intValue = parseInt(value, 10);
        if (isNaN(intValue) || intValue <= 1) {
          setError('Please enter a valid integer above 1.');
          return false;
        }
        setError('');
        return true;
      };
    
      const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        validateInput(value);
      };
    
      const handleSubmit = async () => {
        if (!validateInput(inputValue)) {
          return;
        }
    
        try {
          const response = await fetch('/api/change_margin', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ new_margin : parseInt(inputValue),
                                change_partner_account_id : modalWithData.account_id
             }),
          });
    
          if (!response.ok) {
            alert("An Error occured updating margin...")
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          console.log('Success:', result);
          setOpen(false);
          window.location.reload(); // Refresh the page
        } catch (error) {
          console.error('Error:', error);
        }
      };

  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
    <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 1000,
            borderRadius: "sm",
            p: 3,
            boxShadow: "sm",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.surface",
            }}
          />
          <Typography
            component="h1"
            id="modal-title"
            level="title-lg"
            textColor="inherit"
            fontWeight="lg"
            mb={3}
          >
            Update Traffic Source Margin
          </Typography>
            <div>
                <p><strong>Traffic Source Username: </strong>{modalWithData.username}</p>
            </div>
            <div>
                <p><strong>Current Margin: </strong>{modalWithData.margin * 100}%</p>
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography component="h2" level="body-lg">
                    New % Margin on Traffic Source:
                </Typography>
                <Input
                    placeholder="Type a number..."
                    value={inputValue}
                    onChange={handleInputChange}
                    error={!!error}
                    aria-describedby="error-text"
                />
                {error && (
                    <Typography id="error-text" color="danger">
                    {error}
                    </Typography>
                )}
                <Button onClick={handleSubmit} variant="solid" disabled={!!error}>
                    Confirm
                </Button>
            <Button onClick={handleSubmit} variant="solid" color="danger">
                Cancel
            </Button>            
            </Box>
          

        </Sheet>
      </Modal>
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Username
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Account ID
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Password
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Current Traffic Margin
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Feed Url
              </th>
            </tr>
          </thead>
          <tbody>
            {marginInfo?.map((row, index) => (
              <tr key={`${row.feedId}-${index}`}>
                <td style={{ textAlign: "center" }}>{row["username"]}</td>
                <td style={{ textAlign: "center" }}>{row["account_id"]}</td>
                <td style={{ textAlign: "center" }}>{row["password"]}</td>
                <td style={{ textAlign: "center" }}>{row["margin"] * 100}%
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        setModalWithData(row);
                        setOpen(true);
                    }}
                    sx={{ m: 2 }}
                >
                    Change Margin
                </Button>


                </td>
                <td style={{ textAlign: "center" }}>
                <a 
                    href={`https://www.thecareersphere.com/api/fileserver/jobs?accountId=${row["account_id"]}&username=${row["username"]}&password=${row["password"]}&resource=jobs.xml&feedSizeLimit=40000&minCPC=0.10`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    Load Feed
                </a>

                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}
