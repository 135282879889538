import {
  BrowserRouter,
  Route,
  Routes,
  json,
  Navigate,
  useNavigate
} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop.js";
import ProtectedLayout from "./components/ProtectedLayout.jsx";
import PartnerLogin from "./pages/partner/PartnerLogin.tsx";
import AdminLogin from "./pages/admin/AdminLogin.tsx"
import Home from "./pages/admin/Home.js"
import Feeds from "./pages/admin/Feeds.js"
import Clicks from "./pages/admin/Clicks.js"
import DirectFeeds from "./pages/admin/DirectFeeds.js"
/// ManageCPA.js
import ManageCPA from "./pages/admin/ManageCPA.js"

// import CreateAccount from "./pages/CreateAccount.tsx";
// import ForgotPassword from "./pages/ForgotPassword.tsx";
// import TermsPage from "./pages/TermsPage.tsx";
import axios from "axios";
import { useState, useEffect, useContext, useRef } from "react";
import ReactGA from "react-ga"; // Import react-ga
import Dashboard from "./pages/Dashboard.tsx";
import BuyingTraffic from "./pages/partner/BuyingTraffic.js"
import ReceivingJobs from "./pages/partner/ReceivingJobs.js"
import JobDetail from "./components/admin/JobDetail.jsx"; // Component for individual job pages


// import { login, authFetch, useAuth, logout } from "./auth";
axios.defaults.withCredentials = true;

// Create your first component
const Layout = () => {
  // TODO update all I have to do is check if there is
  // a token in localstorage. current approach below is flimsy!
  const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    let [logged, setLogged] = useState(null);
    const endpoint = "/api/is_authenticated";
    const isMounted = useRef(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(endpoint, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          });
          const jsonData = await response.json();
          if (isMounted.current) {
            // Check if component is still mounted
            if (jsonData.status === "authenticated") {
              setLogged(true);
            } else {
              setLogged(false);
            }
          }
        } catch (error) {
          console.error("Error fetching feed data:", error);
        }
      };
      fetchData();
      return () => {
        isMounted.current = false; // Cleanup: set the flag to false when the component is unmounted
      };
    }, []);

    // If logged is still being determined, show a loading screen or return null
    if (logged === null) return null; // or return <LoadingComponent />;

    // If not logged in, redirect to login
    if (!logged) {
      return <Navigate to="/" replace />;
    }
    // If logged in, render children
    return children;
  };

  useEffect(() => {
    ReactGA.initialize("G-37R6L36HG0");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  //the basename is used when your project is published in a subdirectory and not in the root of the domain
  // you can set the basename on the .env file located at the root of this project, E.g: BASENAME=/react-hello-webapp/
  const basename = ""; //process.env.API_BASE_URL ||

  return (
    <div>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Routes>
            {/* Auth Pages (Public) */}
            <Route element={<Dashboard />} path="/" />
            <Route path="/jobs/:jobId" element={<JobDetail />} />
            {/* <Route element={<ForgotPassword />} path="/partners/forgot-password" /> */}
            {/* <Route element={<CreateAccount />} path="/partners/create-account" /> */}
            {/* <Route element={<TermsPage />} path="/partners/terms" /> */}
            <Route element={<PartnerLogin />} path="/partners/login" />
            <Route element={<AdminLogin />} path="/admin/login" />
            <Route element={<Home />} path="/admin/dash/home" />
            <Route element={<Clicks />} path="/admin/dash/clicks" />
            <Route element={<Feeds/>} path="/admin/dash/feeds" />
            <Route element={<DirectFeeds/>} path="/admin/dash/direct_feeds" />
            {/* /admin/dash/manage_cpa */}
            <Route element={<ManageCPA />} path="/admin/dash/manage_cpa" />
            {/* Dashboard Pages (Private) */}
            {/* <Route path="/partners/home" element={<ProtectedLayout />}>
              <Route index element={<HomePage />} />
            </Route> */}
            <Route path="/partners/receiving_jobs" element={<ProtectedLayout />}>
              <Route index element={<ReceivingJobs />} />
            </Route>
            <Route path="/partners/buying_traffic" element={<ProtectedLayout />}>
              <Route index element={<BuyingTraffic />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
};
export default Layout;
