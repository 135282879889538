import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function JobDetail() {
  const { jobId } = useParams();
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    // Fetch job data based on the jobId
    const fetchJobData = async () => {
        console.log("jobId: ", jobId)
        console.log("* type jobId: ", typeof(jobId))
      const response = await fetch(`/api/job/${jobId}`);
    //   console.log("response: ", response.json())
      const data = await response.json();
      setJobData(data);
    };

    fetchJobData();
  }, [jobId]);

  if (!jobData) {
    return <p>Loading 2...</p>;
  }

  return (
    <div>
      <h1>{jobData?.Position}</h1>
      <p>{jobData?.Description}</p>
      <p>Location: {jobData?.Location}</p>
      <p>Classification: {jobData?.Classification}</p>
    </div>
  );
}

export default JobDetail;
