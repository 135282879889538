import * as React from "react";
import { useState, useMemo } from "react";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";

export default function CPAClickTable({ clickEntries: initialEntries }) {
  const [clickEntries, setClickEntries] = useState(initialEntries);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10; // Set the number of rows per page

  // Sort the data by date (assuming "Timestamp" is a valid date string)
  const sortedReviewStats = useMemo(() => {
    return [...clickEntries].sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
  }, [clickEntries]);

  // Calculate the displayed rows based on the current page
  const displayedRows = sortedReviewStats?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Handle pagination
  const totalPages = Math.ceil(sortedReviewStats?.length / rowsPerPage);

  const handlePrevPage = () => {
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Adjust the current page if the deletion leaves the current page empty
  React.useEffect(() => {
    if (page > totalPages) {
      setPage(totalPages);
    }
  }, [page, totalPages]);

  

  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "none", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
            
          <thead>
            <tr>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Time Clicked
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Job Title
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Company Name
              </th>              
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Feed Name
              </th>              
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Job Price $
              </th>
              <th style={{ padding: "12px 6px", textAlign: "center" }}>
                Apply Completion Time
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedRows?.map((row, index) => (
              <tr key={`${row.feedId}-${index}`}>
                <td style={{ textAlign: "center" }}>{row["clickedAt"]}</td>
                <td style={{ textAlign: "center" }}>{row["job"]["title"]}</td>
                <td style={{ textAlign: "center" }}>{row["job"]["company"]}</td>                
                <td style={{ textAlign: "center" }}>{row["feed"]["name"]}</td>
                <td style={{ textAlign: "center" }}>{row["cost"]}</td>
                <td style={{ textAlign: "center" }}>{row["applyCompletedAt"]}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Custom Pagination Controls */}
        <div style={{ display: "flex", justifyContent: "center", margin: "16px 0" }}>
          <button onClick={handlePrevPage} disabled={page === 1}>
            Previous
          </button>
          <span style={{ margin: "0 10px" }}>
            Page {page} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={page === totalPages}>
            Next
          </button>
        </div>
      </Sheet>
    </React.Fragment>
  );
}

/*
{'id': '9d96e4c1-5f66-4190-99ab-f144c6819c16', 'job': {'id': '3ef91515-cfbd-4f38-91de-03197701ecfb', 'title': 'CDL A Delivery Truck Driver - Immediate Opening', 'company': 'Performance Foodservice'}, 'feed': {'id': '70db493a-ffc3-4889-b450-9b38aa264b33', 'shortCode': 'pd1q3bhg', 'name': 'CPA10'}, 'cost': 7.79, 'clickedAt': '2024-10-09T19:37:25Z', 'applyCompletedAt': None, 'redirectQueryString': '?camp=08o5bvtm&src=pd1q3bhg'}
*/