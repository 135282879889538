        import * as React from "react";
        import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
        import Box from "@mui/joy/Box";
        import CssBaseline from "@mui/joy/CssBaseline";
        import IconButton from "@mui/joy/IconButton";
        // Icons import
        import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
        import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
        import Sidebar from "../../components/admin/Sidebar";
        import Header from "../../components/Header";
        import ClicksChart from "../../components/admin/RevChart.jsx";
        import { Typography } from "@mui/joy";
        import framesxTheme from "../theme";
        import { useNavigate } from "react-router-dom";


        import "react-datepicker/dist/react-datepicker.css";
        import 'react-day-picker/dist/style.css';
        import Table from "@mui/joy/Table";
        import Sheet from "@mui/joy/Sheet";
        // import Typography from '@mui/joy/Typography';
        import Stack from "@mui/joy/Stack";
        import { useState, useEffect } from "react";
        // import DatePicker from "../components/DatePicker";
        import SampleSkeletonTable from "../../components/SampleSkeletonTable";
        import DataTable from "../../components/DataTable";
        import RevEarnedTable from "../../components/admin/RevEarnedTable.jsx";
        import RevSpentTable from "../../components/admin/RevSpentTable"

        function ColorSchemeToggle() {
        const { mode, setMode } = useColorScheme();
        const [mounted, setMounted] = React.useState(false);
        React.useEffect(() => {
            setMounted(true);
        }, []);
        if (!mounted) {
            return null;
        }
        return (
            <IconButton
            id="toggle-mode"
            size="lg"
            variant="solid"
            color="neutral"
            onClick={() => {
                if (mode === "light") {
                setMode("dark");
                } else {
                setMode("light");
                }
            }}
            sx={{
                position: "fixed",
                zIndex: 999,
                top: "1rem",
                right: "1rem",
                borderRadius: "50%",
                boxShadow: "sm"
            }}
            >
            {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
            </IconButton>
        );
        }

        export default function TeamExample() {
        const get_click_data = "/api/get_revenue_charts_data";
        const [loadedTable1, setLoadedTable1] = useState(false);
        const [loadedTable2, setLoadedTable2] = useState(false);
        const [monthXAxis, setMonthXAxis] = useState();
        const [revMonthData, setMonthRevData] = useState();
        const [revDayData, setDayRevData] = useState();
        console.log("revDayData: ", revDayData)
        const navigate = useNavigate();
        // const [data, setData] = useState([]);


        useEffect(() => {
            const fetchData = async () => {      
            try {
                const response = await fetch(get_click_data, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
                // body: JSON.stringify(dataToSend),
                });
                
                if (!response.ok) {
                alert("Your Session expired, please login again!")
                navigate("/admin/login");
                throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                const all_month_data_auth = data["all"].by_month_rev_authored 
                const talent_month_auth =  data["talent.com"].by_month_rev_authored
                const jobg8_month_auth =  data["jobg8"].by_month_rev_authored
                const jobrapido_month_auth = data["jobrapido"].by_month_rev_authored
                const jobget_month_auth = data["jobget"].by_month_rev_authored
                const jooble_month_auth = data["jooble"].by_month_rev_authored
                const jobvertise_month_auth = data["jobvertise"].by_month_rev_authored
                const jobble_month_auth = data["jobble"].by_month_rev_authored

                const all_month_data_sent = data["all"].by_month_rev_sent
                const talent_month_sent =  data["talent.com"].by_month_rev_sent
                const jobg8_month_sent = data["jobg8"].by_month_rev_sent
                const jobrapido_month_sent = data["jobrapido"].by_month_rev_sent
                const jobget_month_sent = data["jobget"].by_month_rev_sent
                const jooble_month_sent = data["jooble"].by_month_rev_sent
                const jobvertise_month_sent = data["jobvertise"].by_month_rev_sent
                const jobble_month_sent = data["jobble"].by_month_rev_sent


                // Maybe just do authored clicks for now
                const all_month_clicks = data["all"].by_month_click_authored // .by_day_click_authored
                const talent_month_auth_clicks = data["talent.com"].by_month_click_authored
                const jobg8_month_auth_clicks = data["jobg8"].by_month_click_authored
                const jobrapido_month_auth_clicks = data["jobrapido"].by_month_click_authored
                const jobget_month_auth_clicks = data["jobget"].by_month_click_authored
                const jooble_month_auth_clicks = data["jooble"].by_month_click_authored
                const jobvertise_month_auth_clicks = data["jobvertise"].by_month_click_authored
                const jobble_month_auth_clicks = data["jobble"].by_month_click_authored


                const monthlyRevData = Object.entries(all_month_data_auth).map(
                    ([key, value]) => ({
                        name: key,
                        "All Earned": value,
                        "talent.com Earned": talent_month_auth[key],
                        "jobg8 Earned" : jobg8_month_auth[key], 
                        "jobrapido Earned" : jobrapido_month_auth[key],
                        "jobget Earned": jobget_month_auth[key],
                        "jooble Earned" : jooble_month_auth[key],
                        "jobvertise Earned": jobvertise_month_auth[key],
                        "jobble Earned": jobble_month_auth[key],
                        
                        "All Spent" : all_month_data_sent[key],
                        "talent.com Spent" : talent_month_sent[key],
                        "jobg8 Spent" : jobg8_month_sent[key],
                        "jobrapido Spent" : jobrapido_month_sent[key],
                        "jobget Spent" : jobget_month_sent[key],
                        "jooble Spent" : jooble_month_sent[key],
                        "jobvertise Spent": jobvertise_month_sent[key],
                        "jobble Spent": jobble_month_sent[key],

                        "All Clicks" : all_month_clicks[key],
                        "talent.com Clicks" : talent_month_auth_clicks[key],
                        "jobg8 Clicks" : jobg8_month_auth_clicks[key],
                        "jobrapido Clicks": jobrapido_month_auth_clicks[key],
                        "jobget Clicks" : jobget_month_auth_clicks[key],
                        "jooble Clicks" : jooble_month_auth_clicks[key], 
                        "jobvertise Clicks": jobvertise_month_auth_clicks[key],
                        "jobble Clicks": jobble_month_auth_clicks[key]
                    })
                );
                setMonthRevData(monthlyRevData)
                setLoadedTable1(true)
                const all_day_data_auth = data["all"].by_day_rev_authored 
                const talent_day_auth =  data["talent.com"].by_day_rev_authored
                const jobg8_day_auth =  data["jobg8"].by_day_rev_authored
                const jobrapido_day_auth = data["jobrapido"].by_day_rev_authored
                const jobget_day_auth = data["jobget"].by_day_rev_authored
                const jooble_day_auth = data["jooble"].by_day_rev_authored
                const jobvertise_day_auth = data["jobvertise"].by_day_rev_authored
                const jobble_day_auth = data["jobble"].by_day_rev_authored

                const all_day_data_sent = data["all"].by_day_rev_sent
                const talent_day_sent =  data["talent.com"].by_day_rev_sent
                const jobg8_day_sent = data["jobg8"].by_day_rev_sent
                const jobrapido_day_sent = data["jobrapido"].by_day_rev_sent
                const jobget_day_sent = data["jobget"].by_day_rev_sent
                const jooble_day_sent = data["jooble"].by_day_rev_sent
                const jobvertise_day_sent = data["jobvertise"].by_day_rev_sent
                const jobble_day_sent = data["jobble"].by_day_rev_sent

                const all_day_clicks = data["all"].by_day_click_authored // .by_day_click_authored
                const talent_day_auth_clicks = data["talent.com"].by_day_click_authored
                const jobg8_day_auth_clicks = data["jobg8"].by_day_click_authored
                const jobrapido_day_auth_clicks = data["jobrapido"].by_day_click_authored
                const jobget_day_auth_clicks = data["jobget"].by_day_click_authored
                const jooble_day_auth_clicks = data["jooble"].by_day_click_authored
                const jobvertise_day_auth_clicks = data["jobvertise"].by_day_click_authored
                const jobble_day_auth_clicks = data["jobble"].by_day_click_authored

                const dailyRevData =  Object.entries(all_day_data_auth).map(
                    ([key, value]) => ({
                        name: key,
                        "All Earned": value,
                        "talent.com Earned" : talent_day_auth[key],
                        "jobg8 Earned" : jobg8_day_auth[key], 
                        "jobrapido Earned" : jobrapido_day_auth[key],
                        "jobget Earned" : jobget_day_auth[key],
                        "jooble Earned" : jooble_day_auth[key],
                        "jobvertise Earned" : jobvertise_day_auth[key],
                        "jobble Earned" : jobble_day_auth[key],

                        
                        "All Spent" : all_day_data_sent[key],
                        "talent.com Spent" : talent_day_sent[key],
                        "jobg8 Spent" : jobg8_day_sent[key],
                        "jobrapido Spent" : jobrapido_day_sent[key],
                        "jobget Spent" : jobget_day_sent[key],
                        "jooble Spent" : jooble_day_sent[key],
                        "jobvertise Spent" : jobvertise_day_sent[key],
                        "jobble Spent" : jobble_day_sent[key],

                        "All Clicks" : all_day_clicks[key],
                        "talent.com Clicks" : talent_day_auth_clicks[key],
                        "jobg8 Clicks" : jobg8_day_auth_clicks[key],
                        "jobrapido Clicks": jobrapido_day_auth_clicks[key],
                        "jobget Clicks" : jobget_day_auth_clicks[key],
                        "jooble Clicks" : jooble_day_auth_clicks[key], 
                        "jobvertise Clicks": jobvertise_day_auth_clicks[key],
                        "jobble Clicks" : jobble_day_auth_clicks[key]
                    })
                );
                setDayRevData(dailyRevData)
                setLoadedTable2(true)


            } catch (error) {
                console.error("Error fetching data:", error);
            }
            };

            fetchData();
        }, []); // Added startDate and endDate as dependencies for useEffect
        

        return (
            <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
            <CssBaseline />
            <Box sx={{ display: "flex", minHeight: "50dvh" }}> {/* Main flex container */}
                <Sidebar /> {/* Sidebar stays directly within the main flex container */}
                <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}> {/* New Box for main content */}
                <Header />
                <ColorSchemeToggle />
                <Box sx={{ width: '100%', my: 2 }}>
                <Typography 
                    component="h1" 
                    variant="h1" 
                    gutterBottom 
                    sx={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}
                    >
                    Home Overview
                    </Typography>
                </Box>
                {loadedTable2 ? (
                    <>
                <Typography 
                    component="h3" 
                    variant="h3" 
                    gutterBottom 
                    sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
                    >
                    Daily Revenue ($)
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                    <ClicksChart reviewStats={revDayData?.slice(-90)}/>
                    </Box>                
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                        <RevEarnedTable reviewStats={revDayData}/>
                        <RevSpentTable reviewStats={revDayData}/>
                    </Box>      
                    </>
 
                ) : (
                    <SampleSkeletonTable />
                )}
                <br/>
                {loadedTable1 ? (
                    <>
                    <Typography 
                        component="h3" 
                        variant="h3" 
                        gutterBottom 
                        sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
                        >
                        Monthly Revenue ($)
                    </Typography>
                     <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                        <ClicksChart reviewStats={revMonthData}/>
                    </Box> 
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                        <RevEarnedTable reviewStats={revMonthData}/>
                        <RevSpentTable reviewStats={revMonthData}/>
                    </Box>
                    </>
                ) : (
                    <SampleSkeletonTable />
                )}
            </Box>
            </Box>
            </CssVarsProvider>
        );
        
        }
