import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";

import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import IconButton from "@mui/joy/IconButton";

// Icons import
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import AspectRatio from "@mui/joy/AspectRatio";

import Sidebar from "../../components/admin/Sidebar";
import Header from "../../components/Header";
import Input from '@mui/joy/Input';
import ClicksChart from "../../components/ClicksChart";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

import { Typography } from "@mui/joy";
import framesxTheme from "../theme";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from 'react-router-dom';
import 'react-day-picker/dist/style.css';
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
// import Typography from '@mui/joy/Typography';
import Stack from "@mui/joy/Stack";
import { useState, useEffect } from "react";
// import DatePicker from "../components/DatePicker";
import SampleSkeletonTable from "../../components/SampleSkeletonTable";
import DataTable from "../../components/DataTable";
import CPAClickTable from "../../components/admin/CPAClickTable";
import SwitchFeed from "../../components/admin/SwitchFeed";
// import IndividualClicksTable from "../../components/admin/IndividualClicksTable";
import Button from "@mui/joy/Button";

import { useNavigate } from "react-router-dom";
import FailedClicksTable from "../../components/admin/FailedClicksTable"

// Page shows how much the user spent.



function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  return (
    <IconButton
      id="toggle-mode"
      size="lg"
      variant="solid"
      color="neutral"
      onClick={() => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
      }}
      sx={{
        position: "fixed",
        zIndex: 999,
        top: "1rem",
        right: "1rem",
        borderRadius: "50%",
        boxShadow: "sm"
      }}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}




export default function TeamExample() {
  const [byMonthClickAuthored, setByMonthClickAuthored] = useState([]);
  const [byDayClickAuthored, setByDayClickAuthored] = useState([]);
  const [failedClickLog, setFailedClickLog] = useState([]);
  

  const navigate = useNavigate();
  const get_click_data = "/api/get_cv_wallet_cpa_clicks";
  
  const [loadedTable, setLoadedTable] = useState(false);
  const [data, setData] = useState([]);
  const [settingsData, setSettingsData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');


  const validateInput = (value) => {
    const intValue = parseInt(value, 10);
    if (isNaN(intValue) || intValue <= 1) {
      setError('Please enter a valid integer above 1.');
      return false;
    }
    setError('');
    return true;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    validateInput(value);
  };

  const handleSubmit = async () => {
    if (!validateInput(inputValue)) {
      return;
    }

    try {
      const response = await fetch('/api/update-feed-cpa-cpc', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ cpcValue :inputValue,
            clientName : "cv_wallet"
         }),
      });

      if (!response.ok) {
        alert("An Error occured updating cpc...")
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log('Success:', result);
      setOpen(false);
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {      
      try {
        const response = await fetch(get_click_data, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {
          alert("Your Session expired, please login again!")
          navigate("/admin/login");
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setLoadedTable(true)
        setData(data)
        console.log("CPA click data: ", data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Added startDate and endDate as dependencies for useEffect


  const get_feed_settings = "/api/get_feed_settings";
  useEffect(() => {
    const fetchData = async () => {      
      try {
        const response = await fetch(get_feed_settings, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("raw data: ", data)
        setSettingsData(data)
        console.log("CPA settings data: ", data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Added startDate and endDate as dependencies for useEffect




  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "50dvh" }}> {/* Main flex container */}
        <Sidebar /> {/* Sidebar stays directly within the main flex container */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}> {/* New Box for main content */}
          <Header />
          <ColorSchemeToggle />
          <Box sx={{ width: '100%', my: 2 }}>
            <Typography 
                    component="h1" 
                    variant="h1" 
                    gutterBottom 
                    sx={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}
                >
                    CPA Clicks Overview
            </Typography>
          </Box>
          <Typography 
                        component="h3" 
                        variant="h3" 
                        gutterBottom 
                        sx={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}
                        >
                        Click Log
            </Typography>
          {loadedTable ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              <CPAClickTable clickEntries={data.items}/>
            </Box>
          ) : (
            <SampleSkeletonTable />
          )}
          <br/>
          <Box>

        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => setOpen(false)}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <Sheet
            variant="outlined"
            sx={{
                maxWidth: 1000,
                borderRadius: "sm",
                p: 3,
                boxShadow: "sm",
            }}
            >
            <ModalClose
                variant="outlined"
                sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "background.surface",
                }}
            />
            <Typography
                component="h1"
                id="modal-title"
                level="title-lg"
                textColor="inherit"
                fontWeight="lg"
                mb={3}
            >
                Update CPA Job Settings
            </Typography>
                <div>
                <strong>Direct Jobs in CPC Feeds: </strong><SwitchFeed />
                <br></br>
                <strong>Current CPC of Direct jobs: </strong> {settingsData?.cpcValue}
                </div>
                <div>
                </div>
                <br/>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography component="h2" level="body-lg">
                        New CPC for all direct jobs (in cents):
                    </Typography>
                    <Input
                        placeholder="Type a number..."
                        value={inputValue}
                        onChange={handleInputChange}
                        error={!!error}
                        aria-describedby="error-text"
                    />
                    {error && (
                        <Typography id="error-text" color="danger">
                        {error}
                        </Typography>
                    )}
                    <Button onClick={handleSubmit} variant="solid" disabled={!!error}>
                        Confirm
                    </Button>
                <Button onClick={handleSubmit} variant="solid" color="danger">
                    Cancel
                </Button>            
                </Box>
            

            </Sheet>
        </Modal>
        <Button
                    onClick={(e) => {
                        e.preventDefault();
                        setOpen(true);
                    }}
                    sx={{ m: 2 }}
                >
                    Update CPA Feed Settings
                </Button>
        </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
  
}
