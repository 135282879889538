import * as React from 'react';
import Switch from '@mui/joy/Switch';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import axios from 'axios';
import { useState, useEffect } from "react";


export default function ExampleTrackChild() {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const get_feed_settings = "/api/get_feed_settings";

  // Fetch initial switch state from server
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(get_feed_settings, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("raw data: ", data);
        setIsSwitchOn(data.cpcActive); // Set the switch state based on the API response
        setIsLoading(false); // Once data is fetched, stop loading
        console.log("CPA settings data: ", data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Stop loading even if there's an error
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures this runs once on component mount

  // Update switch state on the server when toggled
  useEffect(() => {
    if (isLoading) return; // Do not update the state while loading initial data

    const updateSwitchState = async () => {
      try {
        const response = await fetch('/api/update-switch-state', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            switchState: isSwitchOn,
            clientName: "cv_wallet",
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Switch state updated successfully');
      } catch (error) {
        console.error('Error updating switch state:', error);
      }
    };

    updateSwitchState();
  }, [isSwitchOn, isLoading]); // Triggers this effect when `isSwitchOn` changes, but only if not loading

  const handleToggle = () => {
    setIsSwitchOn((prevState) => !prevState);
  };

  // Display loading state if we're fetching data
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack direction="row" spacing={2}>
      <Switch
        checked={isSwitchOn}
        onChange={handleToggle}
        slotProps={{
          track: {
            children: (
              <React.Fragment>
                <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                  On
                </Typography>
                <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                  Off
                </Typography>
              </React.Fragment>
            ),
          },
        }}
        sx={{
          '--Switch-thumbSize': '27px',
          '--Switch-trackWidth': '64px',
          '--Switch-trackHeight': '31px',
        }}
      />
    </Stack>
  );
}
