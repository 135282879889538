      import * as React from 'react';
      import { CssVarsProvider } from '@mui/joy/styles';
      import CssBaseline from '@mui/joy/CssBaseline';
      import Box from '@mui/joy/Box';
      import Stack from '@mui/joy/Stack';
      import NavBar from '../components/NavBar';
      import RentalCard from '../components/RentalCard';
      import HeaderSection from '../components/HeaderSection';
      import Filters from '../components/Filters';
      import Pagination from '../components/Pagination';
      import { useState, useEffect } from "react";
      import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
      import FormControl from '@mui/joy/FormControl';
      import Input from '@mui/joy/Input';
      import Typography from '@mui/joy/Typography';
      import AspectRatio from '@mui/joy/AspectRatio';
      import { Button } from '@mui/joy';
      import { useLocation } from 'react-router-dom';
      import BasicSkeleton from '../components/SampleSkeleton';
      import { Link } from "react-router-dom";

      const get_jobs_endpoint = "/api/get_jobs";
      const mapUrl = "https://www.google.com/maps/place/Bellevue,+NE/@41.1347571,-95.9811185,13z/data=!3m1!4b1!4m6!3m5!1s0x8793884a3912c1ab:0xa68ee4b861d982b0!8m2!3d41.1567003!4d-95.9262498!16zL20vMHg5OXE?authuser=0&entry=ttu"
  

      function useQuery() {
        return new URLSearchParams(useLocation().search);
      }

      export default function RentalDashboard() {
        const query = useQuery();
        const [loadedTable, setLoadedTable] = useState(false);
        const [data, setData] = useState<any[]>([]);
        const [pageNum, setPageNum] = useState(1);
        const [totalListingsNum, setTotalListingsNum] = useState(0);
        const [selectedCard, setSelectedCard] = useState<any>(null);
        const [searchInput, setSearchInput] = useState(query.get("search") || "");

        const listingsText = loadedTable ? (totalListingsNum > 5000 ? '5000+ Jobs Found' : `${totalListingsNum} Jobs Found`) : ("Loading Jobs...")

        const handlePageChange = (newValue: number) => {
          setPageNum(newValue);
          // console.log("page num: ", newValue);
      };
      const handleInputChange = (event) => {
        setSearchInput(event.target.value);
        setPageNum(1);
      };

      const handleApplyClick = () => {
        const url = selectedCard?.ApplicationURL;
        if (url) {
          // Open the URL in a new tab
          window.open(url, '_blank');
        } else {
          // Optional: Handle cases where there is no URL
          console.log("No application URL provided.");
        }
      };

        // Construct the query string
        let queryString = `${selectedCard?.Area}, ${selectedCard?.Location}`;
        
        // Encode the query string
        let encodedQueryString = encodeURIComponent(selectedCard?.Area)+"+SC"//+ encodeURIComponent(selectedCard?.Location)  ;

        // console.log(" e Query String:", encodedQueryString); // Check what is being sent



      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(get_jobs_endpoint, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ page: pageNum, searchInput : searchInput }),  // Sending page number in the request body
            });
            
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // console.log("data: ", data);
            setData(data.jobs);  // Assume setData is defined to set state with fetched data
            setTotalListingsNum(data.totalCount)
            setSelectedCard(data.jobs[0])
            console.log("Logo URL: ", data.jobg8Jobs[0].LogoUrl === null)
            console.log("Data received:", data);
            console.log("in dash request maker: ", pageNum)
          } catch (error) {
            console.error("Error fetching data:", error);
          }
          finally {
            setLoadedTable(true);
          }
        };
        fetchData();
      }, [pageNum, searchInput]);  // Effect runs whenever pageNum changes

        return (
          <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <NavBar />
            <Box
              component="main"
              sx={{
                height: 'calc(100vh - 55px)',
                display: 'grid',
                gridTemplateColumns: { xs: 'auto', md: '60% 40%' },
                gridTemplateRows: 'auto 1fr auto',
              }}
            >
              <Stack
                sx={{
                  backgroundColor: 'background.surface',
                  px: { xs: 2, md: 4 },
                  py: 2,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                }}
              >
                <HeaderSection />
                <div>
            <Stack spacing={1} direction="row" sx={{ mb: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <Input
                  placeholder="Search for Job, Position, or Career Title"
                  value={searchInput}
                  onChange={handleInputChange}  // Attach the change handler
                  startDecorator={<SearchRoundedIcon />}
                  aria-label="Search"
                />
              </FormControl>
            </Stack>
            <Typography level="body-sm">{listingsText}</Typography>
          </div>
          </Stack>
              {/* Conditional display of selected card details or default image */}
              {/* Always display selected card details */}
              <Box
                sx={{
                  gridRow: 'span 3',
                  display: { xs: 'none', sm: 'flex' },  // 'none' on extra-small screens, 'flex' on small screens and up
                  flexDirection: 'column',
                  p: 3,
                  backgroundColor: 'background.level1',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflowY: 'auto', // Make sure this is set correctly
                  maxHeight: 'calc(100vh - 50px)', // Adjust based on your header/footer height
                }}
              >
                {data.length > 0 ? (
                  <>
                  <div style={{textAlign: 'center'}}>
                    <h2>{selectedCard?.Position} at {selectedCard?.AdvertiserName}</h2>
                    <Button onClick={handleApplyClick}>Apply</Button>
                  </div>
                  <br/>
                  <div
                    style={{ marginTop: 0, 
                      overflow: 'hidden',
                    overflowY: 'auto', // Enable vertical scrolling
                    // maxHeight: '300px', // Limit height of the content area for the description
                    width: '100%' // Ensure the div takes up the full width of the container
                    }}
                    dangerouslySetInnerHTML={{ __html: selectedCard?.Description || "" }}
                  />
                </>


                ) : loadedTable ? (<h2>No results found</h2>)  : "Loading Job Results..."}
                <br/>

              </Box>
              <Stack spacing={2} sx={{ px: { xs: 2, md: 4 }, pt: 2, minHeight: 0 }}>
                <Filters setData={setData} setTotalListingsNum={setTotalListingsNum}/>
                {loadedTable ? (
                <Stack spacing={2} sx={{ overflow: 'auto' }}>
                  {data.map((entry, index) => (
                    <RentalCard
                      key={index}
                      advertiserName={entry.AdvertiserName}
                      SalaryMinimum={entry.SalaryMinimum}
                      SalaryMaximum={entry.SalaryMaximum}
                      employment_type={entry.EmploymentType}
                      title={entry.Position}
                      category={entry.Classification}
                      area={entry.Area}
                      location={entry.Location}
                      country={entry.Country}
                      image={entry.LogoURL}
                      applicationurl={entry.ApplicationURL}
                      onClick={() => setSelectedCard(entry)} 
                    />
                  ))}
                </Stack>
                ) : (
                  <BasicSkeleton/>
                )}
              </Stack>
              {/* page size is 100 listings */}
              <Pagination value={pageNum} onChange={handlePageChange} total={Math.ceil(totalListingsNum/100)} />
            </Box>
          </CssVarsProvider>
        );
      }